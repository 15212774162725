import {$axios} from "../http";
import {useAsync} from "react-async-hook";

export const CountriesService = {
    GetSendingCountriesList() {
        return useAsync(async () => {
            return await $axios.get('/admin/sending/countries/')
        }, [])
    },
    GetCountriesList() {
        return useAsync(async () => {
            return await $axios.get('/countries/')
        }, [])
    },
}