import React, {useEffect, useState} from "react";
import {
    CustomInput,
    CustomMobileModal,
    CustomPageSizeInput,
    NoDataIcon,
    TableRowDeleteIcon,
} from "../../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../../helpers/helpers";
import {NotificationsService} from "../../services/NotificationsService";
import moment from "moment/moment";
import parse from 'html-react-parser'
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

const notificationModalInitialState: any = {
    values:{
        title: '',
        message: '',
        created_at: '',
        read: false,
    },
    validation:{
        requestIsSent: false,
        modal_opened: false,
        type: '',
    }
}
export default function NotificationsM() {
    const [openFilter, setOpenFilter] = useState(false)

    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns: []
    })

    const [notificationModal, setNotificationModal] = useState<any>(notificationModalInitialState)

    const notificationList = NotificationsService.GetNotificationsList(table.filter)

    const setNotificationIntoModal = (notificationInfoProps: any)=>{
        setNotificationModal({
            ...notificationModal,
            values:{
                ...notificationModal.values,
                title: notificationInfoProps.notification.title,
                message: notificationInfoProps.notification.message,
                created_at: notificationInfoProps.notification.created_at,
                read: notificationInfoProps.read,
            },
            validation:{
                ...notificationModal.validation,
                modal_opened: true,
            }
        })
        NotificationsService.SetNotificationRead(notificationInfoProps.id)
        setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
    }

    useEffect(()=>{
        if(notificationList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(notificationList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: notificationList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = notificationList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[notificationList.loading, notificationList.error])

    return(
        <div className='defaultWrapper'>
            <div className={`blurBox ${openFilter ? 'blurBox__opened' : ''} z-99`} onClick={()=>{setOpenFilter(false)}}></div>
            <div className={`mobileFilter ${openFilter ? 'mobileFilter__opened' : ''}`}>
                <div className='w-full flex justify-between items-center pb-2 border-b border-b-red-700 z-999'>
                    <h3 className='text-gray-900 text-2xl not-italic font-bold'>Уведомления</h3>
                    <button className='mobileFilterButton' onClick={()=>{
                        setOpenFilter(!openFilter)
                    }}>
                        {openFilter
                            ? <ArrowDropUpIcon/>
                            : <ArrowDropDownIcon/>
                        }
                    </button>
                </div>
                <div className='w-full'>
                    <div className='w-full flex-col'>
                        <div className='flex w-full flex-row justify-start items-center gap-5'>
                            <CustomInput
                                className='text-input'
                                type='date'
                                placeholder='Начало даты'
                                value={table.filter.start_date}
                                onChange={(event) => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            start_date: event.target.value,
                                            page: 1,
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input'
                                type='date'
                                placeholder='Конец даты'
                                value={table.filter.end_date}
                                onChange={(event) => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            end_date: event.target.value,
                                            page: 1,
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-col justify-start items-center'>

                {table.loading
                    ? table.rows.length === 0
                        ?
                        <div className='w-full flex  flex-col justify-center items-center py-32 gap-5'>
                            <div className='loader'></div>
                            <p className='text-gray-500 text-xs not-italic font-medium'>
                                Загрузка
                            </p>
                        </div>
                        :
                        <>
                            {table.rows.map((notification: any, index: number)=>
                                <div key={index}  className='rounded-xl shadow-default bg-white mb-10 p-5 w-full flex justify-between items-center gap-5'
                                     onClick={()=>{
                                         setNotificationIntoModal(notification)
                                     }}
                                >
                                    <div className='flex flex-col justify-center items-start gap-2.5'>
                                        <h1 className={`${notification.read ? 'font-medium' : 'font-bold'} text-sm not-italic text-gray-900`}>
                                            {notification.notification.title}
                                        </h1>
                                        <div className='max-w-notificationWidth overflow-hidden text-gray-600 text-xs not-italic font-normal text-ellipsis'>
                                            {parse(notification.notification.message)}
                                        </div>
                                        <p className='text-gray-400 text-xs not-italic font-normal'>
                                            {moment(notification.notification.created_at).format('YYYY-MM-DD')}
                                        </p>
                                    </div>
                                    <div className='tableRowIconButton'
                                         onClick={(event)=>{
                                             event.preventDefault()
                                             event.stopPropagation()
                                             NotificationsService.DeleteNotification(notification.id).then(()=>{
                                                 setNotificationModal(notificationModalInitialState)
                                                 setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
                                             })
                                         }}
                                    >
                                        <TableRowDeleteIcon/>
                                    </div>
                                </div>
                            )}
                            <div className="w-full bg-white flex justify-between items-center p-2.5 rounded-xl">
                                <div className='w-full flex justify-between items-center gap-4'>
                                    <Pagination
                                        size='small'
                                        count={table.filter.total_pages}
                                        page={table.filter.page}
                                        onChange={(event, value) => {
                                            setTable({
                                                ...table,
                                                filter: {
                                                    ...table.filter,
                                                    page: value,
                                                }
                                            })
                                        }}
                                    />
                                    <CustomPageSizeInput
                                        mobile={true}
                                        value={table.filter.size}
                                        onChange={(e) => {
                                            setTable({
                                                ...table,
                                                filter: {
                                                    ...table.filter,
                                                    page: 1,
                                                    size: CheckForPositiveNumbers(e.target.value),
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    : table.error
                        ?
                        <div className='w-full flex  flex-col justify-center items-center py-32 gap-5'>
                            <NoDataIcon/>
                            <p className='text-gray-500 text-xs not-italic font-medium'>
                                {table.message}
                            </p>
                        </div>
                        : table.rows.length === 0
                            ?
                            <div className='w-full flex  flex-col justify-center items-center py-32 gap-5'>
                                <NoDataIcon/>
                                <p className='text-gray-500 text-xs not-italic font-medium'>
                                    Нет данных
                                </p>
                            </div>
                            :
                            <>
                                {table.rows.map((notification: any, index: number)=>
                                    <div key={index}  className='rounded-xl shadow-default bg-white mb-10 p-5 w-full flex justify-between items-center gap-5'
                                         onClick={()=>{
                                             setNotificationIntoModal(notification)
                                         }}
                                    >
                                        <div className='flex flex-col justify-center items-start gap-2.5'>
                                            <h1 className={`${notification.read ? 'font-medium' : 'font-bold'} text-sm not-italic text-gray-900`}>
                                                {notification.notification.title}
                                            </h1>
                                            <div className='max-w-notificationWidth overflow-hidden text-gray-600 text-xs not-italic font-normal text-ellipsis'>
                                                {parse(notification.notification.message)}
                                            </div>
                                            <p className='text-gray-400 text-xs not-italic font-normal'>
                                                {moment(notification.notification.created_at).format('YYYY-MM-DD')}
                                            </p>
                                        </div>
                                        <div className='tableRowIconButton'
                                             onClick={(event)=>{
                                                 event.preventDefault()
                                                 event.stopPropagation()
                                                 NotificationsService.DeleteNotification(notification.id).then(()=>{
                                                     setNotificationModal(notificationModalInitialState)
                                                     setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
                                                 })
                                             }}
                                        >
                                            <TableRowDeleteIcon/>
                                        </div>
                                    </div>
                                )}
                                <div className="w-full bg-white flex justify-between items-center p-2.5 rounded-xl">
                                    <div className='w-full flex justify-between items-center gap-4'>
                                        <Pagination
                                            size='small'
                                            count={table.filter.total_pages}
                                            page={table.filter.page}
                                            onChange={(event, value) => {
                                                setTable({
                                                    ...table,
                                                    filter: {
                                                        ...table.filter,
                                                        page: value,
                                                    }
                                                })
                                            }}
                                        />
                                        <CustomPageSizeInput
                                            mobile={true}
                                            value={table.filter.size}
                                            onChange={(e) => {
                                                setTable({
                                                    ...table,
                                                    filter: {
                                                        ...table.filter,
                                                        page: 1,
                                                        size: CheckForPositiveNumbers(e.target.value),
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                }
            </div>

            <CustomMobileModal
                open={notificationModal.validation.modal_opened}
                onClose={()=>{
                    setNotificationModal(notificationModalInitialState)
                }}
                children={
                    <div className='defaultWrapper'>
                        <div className='notificationsModal w-full h-full flex flex-col items-start justify-start gap-2.5'>
                            <h1 className='w-full text-start text-gray-900 text-3xl not-italic font-medium mb-5'>
                                {notificationModal.values.title}
                            </h1>
                            <div className='w-full break-words'>
                                {parse(notificationModal.values.message)}
                            </div>
                            <div className='w-full flex justify-end'>
                                <span className='text-gray-600 text-sm not-italic font-normal'>
                                    {moment(notificationModal.values.created_at).format('YYYY-MM-DD')}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    )
}