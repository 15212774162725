import {$axios} from "../http";

export const AuthService = {
    async GetToken(email: string, password: string) {
        return await $axios.post('/token/', {email: email, password: password})
    },
    async Register(data: any) {
        let form_data = new FormData()
        if (data.passport_back_side.name === '') {
            delete data.passport_back_side
        }
        if (data.passport_front_side.name === '') {
            delete data.passport_front_side
        }
        for (let key in data) {
            form_data.append(key, data[key]);
        }
        return await $axios.post('/register/', form_data)
    },
    async GetOtp(email: string) {
        return await $axios.post('/otp/', {email: email})
    },
    async Recover(data: {
        email: string,
        otp: string,
    }) {
        return await $axios.post('/password/recovery/', data)
    }
}