import React, {useEffect} from 'react';
import {
    DesktopLogo,
    HeaderNotificationExistIcon,
    HeaderNotificationIcon, SideBarAddressesIcon, SideBarBellIcon, SideBarContactsIcon,
    SideBarExitIcon,
    SideBarHomeIcon,
} from "../helpers/common";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {removeCookie} from "typescript-cookie";
import {useDispatch, useSelector} from "react-redux";
import {access_token_name, refresh_token_name} from "../http";
import {UserI} from "../models/UserI";
import {PersonalService} from "../services/PersonalService";

function Header() {
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user)
    const location = useLocation()
    const dispatch = useDispatch()
    const setUser = ({...data}:UserI ) =>{
        dispatch({
            type:"UPDATE_DATA",
            payload:{
                ...data,
            }
        })
    }
    const personalInfo = PersonalService.GetPersonalInfo()
    useEffect(()=>{
        if(!personalInfo.loading && !personalInfo.error){
            setUser(personalInfo.result?.data)
        }
    },[personalInfo.loading, personalInfo.error])
    return (
        <div className='header' id='header'>
            <div className="header__mainBar">
                <div className="header-mainBar__user">
                    <div className='header-mainBar__userIcon'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="4" cy="4" r="4" transform="matrix(-1 0 0 1 16 3)" fill="#BD2728" stroke="#BD2728" strokeWidth="1.5"/>
                            <path d="M5 16.9347C5 16.0743 5.54085 15.3068 6.35109 15.0175C10.004 13.7128 13.996 13.7128 17.6489 15.0175C18.4591 15.3068 19 16.0743 19 16.9347V18.2502C19 19.4376 17.9483 20.3498 16.7728 20.1818L15.8184 20.0455C13.2856 19.6837 10.7144 19.6837 8.18162 20.0455L7.22721 20.1818C6.0517 20.3498 5 19.4376 5 18.2502V16.9347Z" fill="#BD2728" stroke="#BD2728" strokeWidth="1.5"/>
                        </svg>
                    </div>
                   <div className='header-mainBar__userInfo'>
                       <span>{user.code}</span>
                       <p>{user.full_name}</p>
                   </div>
                </div>
                <Link to='/notifications' className='headerNotificationIcon'>
                    <HeaderNotificationExistIcon/>
                    <HeaderNotificationIcon/>
                </Link>

            </div>
            <div className="header__sideBar flex flex-col justify-start items-center pt-4 pb-32">
                <div className='header-sideBar__logo mb-12'>
                    <DesktopLogo/>
                </div>
                <div className="header-sideBar__links">
                    <div className='w-full h-full overflow-scroll'>
                        <Link to='/' className={`header-sideBar__link ${location.pathname === '/' ? 'header-sideBar__link_active' : ''}`}>
                            <div>
                                <SideBarHomeIcon/><span>Главная</span>
                            </div>
                        </Link>
                        <Link to='/personal' className={`header-sideBar__link ${location.pathname.includes('/personal') ? 'header-sideBar__link_active' : ''}`}>
                            <div>
                                <SideBarAddressesIcon/><span>Личная страница</span>
                            </div>
                        </Link>
                        <Link to='/addresses' className={`header-sideBar__link ${location.pathname.includes('/addresses') ? 'header-sideBar__link_active' : ''}`}>
                            <div>
                                <SideBarAddressesIcon/><span>Адреса</span>
                            </div>
                        </Link>
                        <Link to='/notifications' className={`header-sideBar__link ${location.pathname.includes('/notifications') ? 'header-sideBar__link_active' : ''}`}>
                            <div>
                                <SideBarBellIcon/><span>Уведомления</span>
                            </div>
                        </Link>
                        <Link to='/contacts' className={`header-sideBar__link ${location.pathname.includes('/contacts') ? 'header-sideBar__link_active' : ''}`}>
                            <div>
                                <SideBarContactsIcon/><span>Контакты</span>
                            </div>
                        </Link>
                    </div>
                    <div className="header-sideBar__link header-sideBar__link_exit" onClick={()=>{
                        removeCookie(access_token_name)
                        removeCookie(refresh_token_name)
                        navigate('/auth')
                    }}>
                        <div>
                            <SideBarExitIcon/><span>Выход</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;