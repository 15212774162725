import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const OrdersService = {
    GetOrderStatusButtons() {
        return useAsync(async () => {
            return await $axios.get('/statuses/')
        }, [])
    },
    GetOrders(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/orders/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetOrder(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get('/orders/' + `${id}/`);
        }, [id])
    },
    async CreateOrder(data: any) {
        return await $axios.post('/orders/', data)
    },
    async UpdateOrder(data: any, id: any) {
        return await $axios.put(`/orders/${id}/`, data)
    },
    async DeleteOrder( id: any) {
        return await $axios.delete(`/orders/${id}/`)
    },
}