import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {AuthService} from "../../../services/AuthService";
import {
    CustomInput,
    CustomSelect,
    PasswordHiddenIcon,
    PasswordVisibleIcon,
    PhotoAddIcon, RollBackIcon
} from "../../../helpers/common";
import {CityService} from "../../../services/CityService";
import {RegionService} from "../../../services/RegionService";
import {MyTimer, ValidatePhoneNumber} from "../../../helpers/helpers";

const registerStateInitialValues = {
    values:{
        full_name: '',
        phone: '',
        email: '',
        address: '',
        region: '',
        city: '',
        password: '',
        confirm_password: '',
        otp: '',
        passport_front_side: new File([""], ""),
        passport_back_side: new File([""], ""),
    },
    validation:{
        emailCheck: false,
        emailHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        phoneCheck: false,
        phoneHelperText: '',
        passwordVisible: false,
        otpCheck: false,
        otpHelperText: '',
        sendCode: true,
        requestIsSent: false,
    }
}

const RegisterModal = ({setAuthSteps}: {setAuthSteps: Dispatch<SetStateAction<string>>}) => {
    const [registerSteps, setRegisterSteps] = useState('1')

    const [registerState, setRegisterState] = useState<any>({
        values: registerStateInitialValues.values,
        validation: registerStateInitialValues.validation
    })

    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        if(registerSteps === '1'){
            setRegisterSteps('2')
        }
        if(registerSteps === '2'){
            setRegisterSteps('3')
        }
        if(registerSteps === '3'){
            setRegisterState({
                ...registerState,
                validation:{
                    ...registerState.validation,
                    requestIsSent: true,
                },
            });
            AuthService.Register(registerState.values).then(()=>{
                window.location.reload()
            }).catch((err)=>{
                if(err.response.data.email){
                    setRegisterState({
                        ...registerState,
                        validation:{
                            ...registerState.validation,
                            emailCheck: true,
                            emailHelperText: err.response.data.email[0],
                            requestIsSent: false,
                        },
                    });
                }
                if(err.response.data.phone){
                    setRegisterState({
                        ...registerState,
                        validation:{
                            ...registerState.validation,
                            phoneCheck: true,
                            phoneHelperText: err.response.data.phone[0],
                            requestIsSent: false,
                        },
                    });
                }
                if(err.response.data.otp){
                    setRegisterState({
                        ...registerState,
                        validation:{
                            ...registerState.validation,
                            otpCheck: true,
                            otpHelperText: err.response.data.otp[0],
                            requestIsSent: false,
                        },
                    });
                }
                if(err.response.data.password){
                    setRegisterState({
                        ...registerState,
                        validation:{
                            ...registerState.validation,
                            passwordCheck: true,
                            passwordHelperText: err.response.data.password[0],
                            requestIsSent: false,
                        },
                    });
                }
            })
        }
    }
    const cities = CityService.GetCitiesList()
    const regions = RegionService.GetRegionsList()

    const time = new Date();
    const currentTimeLeftForCode = MyTimer(time)
    const handleResendCode = () =>{
        setRegisterState({
            ...registerState,
            validation:{
                sendCode: true,
                otpHelperText: 'Код отправлен вам на почту'
            },
        })
        const time = new Date();
        time.setSeconds(time.getSeconds() + 59);
        currentTimeLeftForCode.restart(time)
        AuthService.GetOtp(registerState.values.email).then(()=>{
        }).catch(()=>{
            setRegisterState({
                ...registerState,
                validation:{
                    sendCode: false,
                    otpHelperText: 'Ошибка при отправке кода',
                    otpCheck: true,
                },
            })
        })
    }
    useEffect(()=>{
        if(currentTimeLeftForCode.seconds === 0){
            setRegisterState({
                ...registerState,
                validation:{
                    ...registerState.validation, sendCode: false
                },
            })
        }
    }, [currentTimeLeftForCode.seconds])

    return (
        <div className='flex justify-center items-center w-full min-h-screen h-screen p-5'>
            {registerSteps === '1' &&
                <form onSubmit={handleSubmit} className='flex flex-col justify-start items-center py-60px px-163px bg-white rounded-10px shadow-default max-w-auth-width w-full'>
                    <h1 className="auth__title">Регистрация</h1>
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='ФИО'
                        required={true}
                        label='ФИО'
                        value={registerState.values.full_name}
                        onChange={(event)=>{
                            setRegisterState({
                                ...registerState,
                                values:{
                                    ...registerState.values,
                                    full_name: event.target.value,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Адрес'
                        required={true}
                        label='Адрес'
                        value={registerState.values.address}
                        onChange={(event)=>{
                            setRegisterState({
                                ...registerState,
                                values:{
                                    ...registerState.values,
                                    address: event.target.value,
                                }
                            })
                        }}
                    />
                    <CustomSelect
                        className='default-select'
                        placeholder='Область:'
                        label='Область:'
                        required={true}
                        value={registerState.values.region}
                        onChange={(event)=>{
                            setRegisterState({
                                ...registerState,
                                values:{
                                    ...registerState.values,
                                    region: event.target.value,
                                }
                            })
                        }}
                    >
                        <option value=""></option>
                        {!regions.loading && !regions.error && regions.result?.data.map((region: any, i:number)=>(
                            <option key={i} value={region.id}>{region.name}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        className='default-select'
                        placeholder='Город:'
                        label='Город:'
                        required={true}
                        value={registerState.values.city}
                        onChange={(event)=>{
                            setRegisterState({
                                ...registerState,
                                values:{
                                    ...registerState.values,
                                    city: event.target.value,
                                }
                            })
                        }}
                    >
                        <option value=""></option>
                        {!cities.loading && !cities.error && cities.result?.data.map((city: any, i:number)=>(
                            <option key={i} value={city.id}>{city.name}</option>
                        ))}
                    </CustomSelect>
                    <button disabled={registerState.validation.requestIsSent} type='submit' className='submit-button_orange h-12 w-full mt-4'>
                        {registerState.validation.requestIsSent
                            ?<div className="loader"></div>
                            :'Далее'
                        }
                    </button>
                    <div className='w-full flex justify-center items-center gap-3 py-5'>
                    <span className='text-xs not-italic font-normal text-dark-grey'>
                        У вас есть аккаунт?
                    </span>
                        <p className='text-xs not-italic font-normal hover:underline cursor-pointer'
                           onClick={()=>{setAuthSteps('auth')}}
                        >
                            Войти
                        </p>
                    </div>
                </form>
            }
            {registerSteps === '2' &&
                <form onSubmit={handleSubmit} className='flex flex-col justify-start items-center py-60px px-163px bg-white rounded-10px shadow-default max-w-auth-width w-full relative'>
                    <div className='page__rollBack-icon absolute left-10 top-10' onClick={()=>{setRegisterSteps('1')}}>
                        <div>
                            <RollBackIcon/>
                        </div>
                        {/*<p>Назад</p>*/}
                    </div>
                    <h1 className="auth__title">Регистрация</h1>

                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Передняя сторона паспорта'
                        required={false}
                        readOnly={true}
                        label=''
                        value={registerState.values.passport_front_side.name}
                        helperText={registerState.validation.passport_front_sideHelperText}
                        error={registerState.validation.passport_front_sideCheck}
                        inputProps={
                            <div className='text-input_icon_button'>
                                {registerState.values.passport_front_side.name === ''
                                    ?<label className='flex justify-center items-center cursor-pointer w-full h-full'>
                                        <input type="file" accept="image/*"
                                               onChange={(event)=>{
                                                   let files: FileList | null = event.currentTarget.files;
                                                   setRegisterState({
                                                       ...registerState,
                                                       values:{
                                                           ...registerState.values,
                                                           passport_front_side: files?.item(0),
                                                       }
                                                   })
                                               }}/>
                                        <PhotoAddIcon/>
                                    </label>
                                    :<div className='flex justify-center items-center cursor-pointer w-full h-full rotate-45'
                                          onClick={()=>{
                                              setRegisterState({
                                                  ...registerState,
                                                  values:{
                                                      ...registerState.values,
                                                      passport_front_side: new File([""], ""),
                                                  }
                                              })
                                          }}
                                    >
                                        <PhotoAddIcon/>
                                    </div>
                                }
                            </div>
                        }
                    />
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Задняя сторона паспорта'
                        required={false}
                        readOnly={true}
                        label=''
                        value={registerState.values.passport_back_side.name}
                        helperText={registerState.validation.passport_back_sideHelperText}
                        error={registerState.validation.passport_back_sideCheck}
                        inputProps={
                            <div className='text-input_icon_button'>
                                {registerState.values.passport_back_side.name === ''
                                    ?<label className='flex justify-center items-center cursor-pointer w-full h-full'>
                                        <input type="file" accept="image/*"
                                               onChange={(event)=>{
                                                   let files: FileList | null = event.currentTarget.files;
                                                   setRegisterState({
                                                       ...registerState,
                                                       values:{
                                                           ...registerState.values,
                                                           passport_back_side: files?.item(0),
                                                       }
                                                   })
                                               }}/>
                                        <PhotoAddIcon/>
                                    </label>
                                    :<div className='flex justify-center items-center cursor-pointer w-full h-full rotate-45'
                                          onClick={()=>{
                                              setRegisterState({
                                                  ...registerState,
                                                  values:{
                                                      ...registerState.values,
                                                      passport_back_side: new File([""], ""),
                                                  }
                                              })
                                          }}
                                    >
                                        <PhotoAddIcon/>
                                    </div>
                                }
                            </div>
                        }
                    />

                    <button disabled={registerState.validation.requestIsSent} type='submit' className='submit-button_orange h-12 w-full mt-4'>
                        {registerState.validation.requestIsSent
                            ?<div className="loader"></div>
                            :'Далее'
                        }
                    </button>
                    <div className='w-full flex justify-center items-center gap-3 py-5'>
                    <span className='text-xs not-italic font-normal text-dark-grey'>
                        У вас есть аккаунт?
                    </span>
                        <p className='text-xs not-italic font-normal hover:underline cursor-pointer'
                           onClick={()=>{setAuthSteps('auth')}}
                        >
                            Войти
                        </p>
                    </div>
                </form>
            }
            {registerSteps === '3' &&
                <form onSubmit={handleSubmit} className='flex flex-col justify-start items-center py-60px px-163px bg-white rounded-10px shadow-default max-w-auth-width relative w-full'>
                    <div className='page__rollBack-icon absolute left-10 top-10' onClick={()=>{setRegisterSteps('2')}}>
                        <div>
                            <RollBackIcon/>
                        </div>
                        {/*<p>Назад</p>*/}
                    </div>
                    <h1 className="auth__title">Регистрация</h1>
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Номер телефона'
                        required={true}
                        label='Номер телефона'
                        value={registerState.values.phone}
                        onChange={(event)=>{
                            setRegisterState({
                                ...registerState,
                                values:{
                                    ...registerState.values,
                                    phone: ValidatePhoneNumber(event.target.value),
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='text-input'
                        type='email'
                        placeholder='Email'
                        required={true}
                        label='Email'
                        value={registerState.values.email}
                        onChange={(event)=>{
                            setRegisterState({
                                ...registerState,
                                values:{
                                    ...registerState.values,
                                    email: event.target.value,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Код'
                        required={true}
                        disabled={registerState.values.email === ''}
                        label=''
                        value={registerState.values.otp}
                        onChange={(event)=>{setRegisterState({
                            ...registerState,
                            values:{
                                ...registerState.values,
                                otp: event.target.value,
                            },
                        })}}
                        helperText={registerState.validation.otpHelperText}
                        error={registerState.validation.otpCheck}
                        inputProps={
                            <button disabled={registerState.values.email === ''} type='button' className='otp_icon_button'>
                                {!registerState.validation.sendCode
                                    ? <p onClick={()=>handleResendCode()}>Отправить код</p>
                                    : <p>0:{currentTimeLeftForCode.seconds}</p>
                                }
                            </button>
                        }
                    />
                    <CustomInput
                        className='text-input'
                        type={registerState.validation.passwordVisible ? 'text' : 'password'}
                        placeholder='Пароль'
                        label=''
                        value={registerState.values.password}
                        helperText={registerState.validation.passwordHelperText}
                        required={true}
                        onChange={(event)=>{setRegisterState({
                            ...registerState,
                            values:{
                                ...registerState.values,
                                password: event.target.value,
                            },
                        })}}
                        error={registerState.validation.passwordCheck}
                        inputProps={
                            <div className='text-input_icon_button' onClick={()=>{
                                setRegisterState({
                                    ...registerState,
                                    validation:{
                                        ...registerState.validation,
                                        passwordVisible: !registerState.validation.passwordVisible,
                                    },
                                })
                            }}>
                                {registerState.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                            </div>
                        }
                    />
                    <CustomInput
                        className='text-input'
                        type={registerState.validation.passwordVisible ? 'text' : 'password'}
                        placeholder='Подтвердите пароль'
                        label=''
                        value={registerState.values.confirm_password}
                        helperText={registerState.values.password !== registerState.values.confirm_password ? 'Пароли не совпадают!' : ''}
                        required={true}
                        onChange={(event)=>{setRegisterState({
                            ...registerState,
                            values:{
                                ...registerState.values,
                                confirm_password: event.target.value,
                            },
                        })}}
                        error={registerState.values.password !== registerState.values.confirm_password}
                        inputProps={
                            <div className='text-input_icon_button' onClick={()=>{
                                setRegisterState({
                                    ...registerState,
                                    validation:{
                                        ...registerState.validation,
                                        passwordVisible: !registerState.validation.passwordVisible,
                                    },
                                })
                            }}>
                                {registerState.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                            </div>
                        }
                    />
                    <button disabled={registerState.validation.requestIsSent} type='submit' className='submit-button_orange h-12 w-full mt-4'>
                        {registerState.validation.requestIsSent
                            ?<div className="loader"></div>
                            :'Готово'
                        }
                    </button>
                    <div className='w-full flex justify-center items-center gap-3 py-5'>
                    <span className='text-xs not-italic font-normal text-dark-grey'>
                        У вас есть аккаунт?
                    </span>
                        <p className='text-xs not-italic font-normal hover:underline cursor-pointer'
                           onClick={()=>{setAuthSteps('auth')}}
                        >
                            Войти
                        </p>
                    </div>
                </form>
            }
        </div>
    );
};

export default RegisterModal;