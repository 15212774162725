import React, { useState} from "react";
import AuthModal from "./AuthModal";
import RegisterModal from "./RegisterModal";
import RecoverModal from "./RecoverModal";

function Auth(){
    const [authSteps, setAuthSteps] = useState('auth');
    return (
        <>
            {authSteps === 'auth' && <AuthModal setAuthSteps={setAuthSteps}/>}
            {authSteps === 'register' && <RegisterModal setAuthSteps={setAuthSteps}/>}
            {authSteps === 'recover' && <RecoverModal setAuthSteps={setAuthSteps}/>}
        </>
    );
}

export default Auth;