import React from 'react';
import {Outlet} from "react-router-dom";
import HeaderM from "./HeaderM";
import FooterM from "./FooterM";

function LayoutM() {
    return (
        <div className='layoutWrapper pt-24 pb-24'>
            <HeaderM/>
            <Outlet/>
            <FooterM/>
        </div>
    );
}

export default LayoutM;