import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {AuthService} from "../../../services/AuthService";
import {MyTimer} from "../../../helpers/helpers";
import {
    CustomInput,
} from "../../../helpers/common";

const recoverStateInitialValues = {
    values: {
        email: '',
        otp: '',
    },
    validation: {
        emailCheck: false,
        emailHelperText: '',
        otpCheck: false,
        otpHelperText: '',
        sendCode: true,
        requestIsSent: false,
    }
}

const RecoverModal = ({setAuthSteps}: { setAuthSteps: Dispatch<SetStateAction<string>> }) => {

    const [recoverState, setRecoverState] = useState<any>({
        values: recoverStateInitialValues.values,
        validation: recoverStateInitialValues.validation
    })

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        setRecoverState({
            ...recoverState,
            validation: {
                ...recoverState.validation,
                requestIsSent: true,
            },
        });
        AuthService.Recover(recoverState.values).then(() => {
            window.location.reload()
        }).catch((err) => {
            if (err.response.data.email) {
                setRecoverState({
                    ...recoverState,
                    validation: {
                        ...recoverState.validation,
                        emailCheck: true,
                        emailHelperText: err.response.data.email[0],
                        requestIsSent: false,
                    },
                });
            }
            if (err.response.data.phone) {
                setRecoverState({
                    ...recoverState,
                    validation: {
                        ...recoverState.validation,
                        phoneCheck: true,
                        phoneHelperText: err.response.data.phone[0],
                        requestIsSent: false,
                    },
                });
            }
            if (err.response.data.otp) {
                setRecoverState({
                    ...recoverState,
                    validation: {
                        ...recoverState.validation,
                        otpCheck: true,
                        otpHelperText: err.response.data.otp[0],
                        requestIsSent: false,
                    },
                });
            }
            if (err.response.data.password) {
                setRecoverState({
                    ...recoverState,
                    validation: {
                        ...recoverState.validation,
                        passwordCheck: true,
                        passwordHelperText: err.response.data.password[0],
                        requestIsSent: false,
                    },
                });
            }
        })
    }

    const time = new Date();
    const currentTimeLeftForCode = MyTimer(time)
    const handleResendCode = () => {
        setRecoverState({
            ...recoverState,
            validation: {
                sendCode: true,
                otpHelperText: 'Код отправлен вам на почту'
            },
        })
        const time = new Date();
        time.setSeconds(time.getSeconds() + 59);
        currentTimeLeftForCode.restart(time)
        AuthService.GetOtp(recoverState.values.email).then(() => {
        }).catch(() => {
            setRecoverState({
                ...recoverState,
                validation: {
                    sendCode: false,
                    otpHelperText: 'Ошибка при отправке кода',
                    otpCheck: true,
                },
            })
        })
    }
    useEffect(() => {
        if (currentTimeLeftForCode.seconds === 0) {
            setRecoverState({
                ...recoverState,
                validation: {
                    ...recoverState.validation, sendCode: false
                },
            })
        }
    }, [currentTimeLeftForCode.seconds])

    return (
        <div className='flex justify-center items-center w-full min-h-screen h-screen p-5'>
            <form onSubmit={handleSubmit}
                  className='flex flex-col justify-start items-center py-60px px-150px bg-white rounded-10px shadow-default max-w-auth-width w-full'>
                <h1 className="auth__title">Восстановить пароль</h1>
                <CustomInput
                    className='text-input'
                    type='email'
                    placeholder='Email'
                    required={true}
                    label='Email'
                    value={recoverState.values.email}
                    onChange={(event) => {
                        setRecoverState({
                            ...recoverState,
                            values: {
                                ...recoverState.values,
                                email: event.target.value,
                            }
                        })
                    }}
                />
                <CustomInput
                    className='text-input'
                    type='text'
                    placeholder='Код'
                    required={true}
                    disabled={recoverState.values.email === ''}
                    label=''
                    value={recoverState.values.otp}
                    onChange={(event) => {
                        setRecoverState({
                            ...recoverState,
                            values: {
                                ...recoverState.values,
                                otp: event.target.value,
                            },
                        })
                    }}
                    helperText={recoverState.validation.otpHelperText}
                    error={recoverState.validation.otpCheck}
                    inputProps={
                        <button disabled={recoverState.values.email === ''} type='button' className='otp_icon_button'>
                            {!recoverState.validation.sendCode
                                ? <p onClick={() => handleResendCode()}>Отправить код</p>
                                : <p>0:{currentTimeLeftForCode.seconds}</p>
                            }
                        </button>
                    }
                />
                <button disabled={recoverState.validation.requestIsSent} type='submit'
                        className='submit-button_orange h-12 w-full mt-4'>
                    {recoverState.validation.requestIsSent
                        ? <div className="loader"></div>
                        : 'Восстановить'
                    }
                </button>
                <div className='w-full flex justify-center items-center gap-3 py-5'>
                    <span className='text-xs not-italic font-normal text-dark-grey'>
                        Нет аккаунта?
                    </span>
                    <p className='text-xs not-italic font-normal hover:underline cursor-pointer'
                       onClick={() => {
                           setAuthSteps('register')
                       }}
                    >
                        Зарегистрируйтесь
                    </p>
                </div>
            </form>
        </div>
    );
};

export default RecoverModal;