import React, {useEffect} from 'react';
import {
    HeaderNotificationExistIcon,
    HeaderNotificationIcon, MobileLogo
} from "../helpers/common";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {UserI} from "../models/UserI";
import {PersonalService} from "../services/PersonalService";

function HeaderM() {
    const dispatch = useDispatch()
    const setUser = ({...data}:UserI ) =>{
        dispatch({
            type:"UPDATE_DATA",
            payload:{
                ...data,
            }
        })
    }
    const personalInfo = PersonalService.GetPersonalInfo()
    useEffect(()=>{
        if(!personalInfo.loading && !personalInfo.error){
            setUser(personalInfo.result?.data)
        }
    },[personalInfo.loading, personalInfo.error])
    return (
        <div className='fixed w-full flex justify-center items-center top-0 h-16 bg-white z-99999 shadow-default'>
            <div className='defaultWrapper'>
                <div className='w-full h-full flex justify-between items-center'>
                    <Link to='/'>
                        <MobileLogo/>
                    </Link>
                    <Link to='/notifications' className='headerNotificationIcon'>
                        <HeaderNotificationExistIcon/>
                        <HeaderNotificationIcon/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HeaderM;