import React, {useState} from "react";
import {
    CustomForm,
    CustomInput, PasswordHiddenIcon, PasswordVisibleIcon,
    RollBackIcon,
} from "../../helpers/common";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {PersonalService} from "../../services/PersonalService";

const passwordModalInitialState = {
    values: {
        old_password: '',
        password: '',
        confirm_password: '',
    },
    validation:{
        old_passwordCheck: false,
        old_passwordHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        passwordVisible: false,
        requestIsSent: false,
        modal_opened: false,
        type: '',
    }
}
export default function Personal() {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user)

    const [passwordModal, setPasswordModal] = useState(passwordModalInitialState)

    const submitPasswordForm = ()=>{
        setPasswordModal({
            ...passwordModal,
            validation: {
                ...passwordModal.validation,
                requestIsSent: true,
            },
        })
        PersonalService.ChangePassword(passwordModal.values).then(()=>{
            setPasswordModal(passwordModalInitialState)
        }).catch((err)=>{
            console.log(err)
        })
    }
    return(
        <div className='componentMainWrapper'>
            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Личная страница</h1>
            </div>

            <div className='flex w-full flex-col justify-start items-start bg-white rounded-xl py-8 mb-12'>
                <div className='flex w-full flex flex-col justify-start items-start'>
                    <div className="personalDetails__titleBox w-full px-8 flex justify-between items-center mb-10">
                        <h3>{user.full_name}</h3>
                        <div className='flex items-center gap-7'>
                            <button className='text-gray-900 text-sm not-italic font-bold hover:underline'
                                    onClick={()=>{setPasswordModal({
                                        ...passwordModal,
                                        validation: {
                                            ...passwordModal.validation,
                                            modal_opened: true,
                                        }
                                    })}}
                            >
                                Изменить пароль
                            </button>
                            <button className='cancel-button_orange h-10'
                                    onClick={()=>{navigate('/personalEdit')}}
                            >
                                Редактировать
                            </button>
                        </div>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                        <div className='personalDetails__textHeader h-8 items-center bg-tableHeaderColor w-full px-8 grid grid-cols-4 gap-x-6'>
                            <div><p>Код клиента</p></div>
                            <div><p>Почта</p></div>
                            <div><p>Номер</p></div>
                            <div><p>Адрес</p></div>
                        </div>
                        <div className='personalDetails__textBody items-center w-full px-8 grid grid-cols-4 gap-x-6'>
                            <div><p>{user.code}</p></div>
                            <div><p>{user.email}</p></div>
                            <div><p>{user.phone}</p></div>
                            <div><p>{user.address}</p></div>
                        </div>
                    </div>

                    <h3 className='personalDetails__subtitle w-full px-8'>
                        Паспортные данные
                    </h3>

                    <div className='w-full grid grid-cols-2 gap-7 px-8 mt-5'>
                        <div className='w-full flex flex-col justify-start items-start gap-5'>
                            <h5 className='text-gray-600 text-sm not-italic font-normal'>Лицевая сторона</h5>
                            <div className='w-full h-56 flex justify-center items-center p-2.5 bg-gray-200 rounded-xl' >
                                <img className='h-full flex-shrink-0 object-cover' src={user.passport_front_side} alt=""/>
                            </div>
                        </div>
                        <div className='w-full flex flex-col justify-start items-start gap-5'>
                            <h5 className='text-gray-600 text-sm not-italic font-normal'>Обратная сторона</h5>
                            <div className='w-full h-56 flex justify-center items-center p-2.5 bg-gray-200 rounded-xl' >
                                <img className='h-full flex-shrink-0 object-cover' src={user.passport_back_side} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CustomForm
                open={passwordModal.validation.modal_opened}
                title={'Изменить пароль'}
                style={{maxWidth: '844px', width:'100%'}}
                buttonText={'Готово'}
                cancelButton={true}
                cancelButtonText='Отмена'
                onClose={()=>{
                    setPasswordModal(passwordModalInitialState)
                }}
                onSubmit={()=>{
                    submitPasswordForm()
                }}
                disabled={passwordModal.validation.requestIsSent}
                requestIsSent={passwordModal.validation.requestIsSent}
                children={
                    <div className=' w-full grid grid-cols-2 gap-x-5 gap-y-5'>
                        <CustomInput
                            className='text-input'
                            type={passwordModal.validation.passwordVisible ? 'text' : 'password'}
                            placeholder='Старый пароль'
                            label='Старый пароль'
                            value={passwordModal.values.old_password}
                            helperText={passwordModal.validation.old_passwordHelperText}
                            required={true}
                            onChange={(event)=>{setPasswordModal({
                                ...passwordModal,
                                values:{
                                    ...passwordModal.values,
                                    old_password: event.target.value,
                                },
                            })}}
                            error={passwordModal.validation.old_passwordCheck}
                            inputProps={
                                <div className='text-input_icon_button' onClick={()=>{
                                    setPasswordModal({
                                        ...passwordModal,
                                        validation:{
                                            ...passwordModal.validation,
                                            passwordVisible: !passwordModal.validation.passwordVisible,
                                        },
                                    })
                                }}>
                                    {passwordModal.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                </div>
                            }
                        />
                        <CustomInput
                            className='text-input'
                            type={passwordModal.validation.passwordVisible ? 'text' : 'password'}
                            placeholder='Пароль'
                            label='Пароль'
                            value={passwordModal.values.password}
                            helperText={passwordModal.validation.passwordHelperText}
                            required={true}
                            onChange={(event)=>{setPasswordModal({
                                ...passwordModal,
                                values:{
                                    ...passwordModal.values,
                                    password: event.target.value,
                                },
                            })}}
                            error={passwordModal.validation.passwordCheck}
                            inputProps={
                                <div className='text-input_icon_button' onClick={()=>{
                                    setPasswordModal({
                                        ...passwordModal,
                                        validation:{
                                            ...passwordModal.validation,
                                            passwordVisible: !passwordModal.validation.passwordVisible,
                                        },
                                    })
                                }}>
                                    {passwordModal.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                </div>
                            }
                        />
                        <CustomInput
                            className='text-input'
                            type={passwordModal.validation.passwordVisible ? 'text' : 'password'}
                            placeholder='Подтвердите пароль'
                            label='Подтвердите пароль'
                            value={passwordModal.values.confirm_password}
                            helperText={passwordModal.values.password !== passwordModal.values.confirm_password ? 'Пароли не совпадают!' : ''}
                            required={true}
                            onChange={(event)=>{setPasswordModal({
                                ...passwordModal,
                                values:{
                                    ...passwordModal.values,
                                    confirm_password: event.target.value,
                                },
                            })}}
                            error={passwordModal.values.password !== passwordModal.values.confirm_password}
                            inputProps={
                                <div className='text-input_icon_button' onClick={()=>{
                                    setPasswordModal({
                                        ...passwordModal,
                                        validation:{
                                            ...passwordModal.validation,
                                            passwordVisible: !passwordModal.validation.passwordVisible,
                                        },
                                    })
                                }}>
                                    {passwordModal.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                </div>
                            }
                        />
                    </div>
                }
            />
        </div>
    )
}