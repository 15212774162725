import React, {useState} from "react";
import {
    CustomInput, CustomMobileModal, PasswordHiddenIcon, PasswordVisibleIcon, PersonalEditIcon,
} from "../../helpers/common";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {PersonalService} from "../../services/PersonalService";

const passwordModalInitialState = {
    values: {
        old_password: '',
        password: '',
        confirm_password: '',
    },
    validation:{
        old_passwordCheck: false,
        old_passwordHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        passwordVisible: false,
        requestIsSent: false,
        modal_opened: false,
        type: '',
    }
}
export default function PersonalM() {
    const navigate = useNavigate();

    const user = useSelector((state: any) => state.user)

    const [passwordModal, setPasswordModal] = useState(passwordModalInitialState)

    const submitPasswordForm = (event: React.FormEvent)=>{
        event.preventDefault()
        setPasswordModal({
            ...passwordModal,
            validation: {
                ...passwordModal.validation,
                requestIsSent: true,
            },
        })
        PersonalService.ChangePassword(passwordModal.values).then(()=>{
            setPasswordModal(passwordModalInitialState)
        }).catch((err)=>{
            console.log(err)
        })
    }
    return(
        <div className='defaultWrapper'>
            <div className='w-full mb-5'>
                <h3 className='text-gray-900 text-2xl not-italic font-bold'>Личная страница</h3>
            </div>

            <div className='flex w-full flex-col justify-start items-start bg-white rounded-xl p-6 mb-5 gap-5'>
                <div className='w-full flex justify-between items-start gap-3'>
                    <div className='flex flex-col justify-start items-start gap-2.5'>
                        <p className='text-gray-900 text-lg not-italic font-medium'>
                            {user.full_name}
                        </p>
                        <span className='text-gray-600 text-xs not-italic font-normal'>
                            {user.code}
                        </span>
                    </div>
                    <button className='px-3 h-10 w-12 min-w-12 bg-red-700 rounded-2xl'
                            onClick={()=>{navigate('/personalEdit')}}
                    >
                        <PersonalEditIcon/>
                    </button>
                </div>

                <p className='text-red-700 text-sm not-italic font-bold underline'
                   onClick={()=>{
                       setPasswordModal({
                           ...passwordModal,
                           validation: {
                               ...passwordModal.validation,
                               modal_opened: true,
                           }
                       })
                   }}
                >
                    Изменить пароль
                </p>
            </div>

            <div className='flex w-full flex-col justify-start items-start bg-white rounded-xl p-6 mb-5 gap-5'>
                <div className='w-full grid grid-cols-2 gap-3'>
                    <div className='w-full flex flex-col justify-start items-start gap-2'>
                        <p className='text-yellow-400 text-xs not-italic font-normal'>
                            Получатель:
                        </p>
                        <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {user.full_name}
                                    </span>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start gap-2'>
                        <p className='text-yellow-400 text-xs not-italic font-normal'>
                            Код клиента:
                        </p>
                        <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {user.code}
                                    </span>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start gap-2'>
                        <p className='text-yellow-400 text-xs not-italic font-normal'>
                            Номер:
                        </p>
                        <span className='text-gray-900 text-xs not-italic font-normal'>
                                       {user.phone}
                                    </span>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start gap-2'>
                        <p className='text-yellow-400 text-xs not-italic font-normal'>
                            Адрес:
                        </p>
                        <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {user.address}
                                    </span>
                    </div>
                </div>
            </div>

            <div className='flex w-full flex-col justify-start items-start bg-white rounded-xl p-6 mb-5 gap-5'>
                <div className='flex w-full flex flex-col justify-start items-start'>
                    <h3 className='text-gray-900 text-sm not-italic font-bold'>
                        Паспортные данные
                    </h3>
                    <div className='w-full flex flex-col gap-7 mt-5'>
                        <div className='w-full flex flex-col justify-start items-start gap-5'>
                            <h5 className='text-gray-600 text-sm not-italic font-normal'>Лицевая сторона</h5>
                            <div className='w-full h-56 flex justify-center items-center p-2.5 bg-gray-200 rounded-xl' >
                                <img className='h-full flex-shrink-0 object-cover' src={user.passport_front_side} alt=""/>
                            </div>
                        </div>
                        <div className='w-full flex flex-col justify-start items-start gap-5'>
                            <h5 className='text-gray-600 text-sm not-italic font-normal'>Обратная сторона</h5>
                            <div className='w-full h-56 flex justify-center items-center p-2.5 bg-gray-200 rounded-xl' >
                                <img className='h-full flex-shrink-0 object-cover' src={user.passport_back_side} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CustomMobileModal
                open={passwordModal.validation.modal_opened}
                onClose={()=>{
                    setPasswordModal(passwordModalInitialState)
                }}
                children={
                    <div className='defaultWrapper'>
                        <form onSubmit={submitPasswordForm} className=' w-full h-full flex flex-col items-center justify-start gap-2.5 overflow-scroll'>
                            <h1 className='w-full text-center text-gray-900 text-3xl not-italic font-medium mb-10'>
                                Изменить пароль
                            </h1>

                            <CustomInput
                                className='text-input'
                                type={passwordModal.validation.passwordVisible ? 'text' : 'password'}
                                placeholder='Старый пароль'
                                label='Старый пароль'
                                value={passwordModal.values.old_password}
                                helperText={passwordModal.validation.old_passwordHelperText}
                                required={true}
                                onChange={(event)=>{setPasswordModal({
                                    ...passwordModal,
                                    values:{
                                        ...passwordModal.values,
                                        old_password: event.target.value,
                                    },
                                })}}
                                error={passwordModal.validation.old_passwordCheck}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={()=>{
                                        setPasswordModal({
                                            ...passwordModal,
                                            validation:{
                                                ...passwordModal.validation,
                                                passwordVisible: !passwordModal.validation.passwordVisible,
                                            },
                                        })
                                    }}>
                                        {passwordModal.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                            <CustomInput
                                className='text-input'
                                type={passwordModal.validation.passwordVisible ? 'text' : 'password'}
                                placeholder='Пароль'
                                label='Пароль'
                                value={passwordModal.values.password}
                                helperText={passwordModal.validation.passwordHelperText}
                                required={true}
                                onChange={(event)=>{setPasswordModal({
                                    ...passwordModal,
                                    values:{
                                        ...passwordModal.values,
                                        password: event.target.value,
                                    },
                                })}}
                                error={passwordModal.validation.passwordCheck}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={()=>{
                                        setPasswordModal({
                                            ...passwordModal,
                                            validation:{
                                                ...passwordModal.validation,
                                                passwordVisible: !passwordModal.validation.passwordVisible,
                                            },
                                        })
                                    }}>
                                        {passwordModal.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                            <CustomInput
                                className='text-input'
                                type={passwordModal.validation.passwordVisible ? 'text' : 'password'}
                                placeholder='Подтвердите пароль'
                                label='Подтвердите пароль'
                                value={passwordModal.values.confirm_password}
                                helperText={passwordModal.values.password !== passwordModal.values.confirm_password ? 'Пароли не совпадают!' : ''}
                                required={true}
                                onChange={(event)=>{setPasswordModal({
                                    ...passwordModal,
                                    values:{
                                        ...passwordModal.values,
                                        confirm_password: event.target.value,
                                    },
                                })}}
                                error={passwordModal.values.password !== passwordModal.values.confirm_password}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={()=>{
                                        setPasswordModal({
                                            ...passwordModal,
                                            validation:{
                                                ...passwordModal.validation,
                                                passwordVisible: !passwordModal.validation.passwordVisible,
                                            },
                                        })
                                    }}>
                                        {passwordModal.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                            <button type='submit' disabled={passwordModal.validation.requestIsSent} className='submit-button_orange h-10 mb-10 flex-shrink-0'>
                                {passwordModal.validation.requestIsSent
                                    ? <div className='loader'></div>
                                    : 'Изменить'
                                }
                            </button>
                        </form>
                    </div>
                }
            />
        </div>
    )
}